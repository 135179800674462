import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';


const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};







function StyledDropzone(props) {

    const onDrop = acceptedFiles => {
        let file = acceptedFiles[0];
        const formData = new FormData();
        formData.append("file", file, file.name);
        let _appendedFile = formData.get("file");
        
        let isValidFile = _appendedFile && _appendedFile.size > 0;
        if (isValidFile) {
            props.onDrop(acceptedFiles)
        } else {
            onDropRejected()
        }
    };

    const onDropRejected = () => {
        props.onDropRejected()
    }


    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: {
            'application/octet-sream': ['.ipynb']
        },
        onDrop,
        onDropRejected
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <div className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drop your Jupyter Notebook here, or click to select (.ipynb)</p>
            </div>
        </div>
    );
}

export default StyledDropzone;