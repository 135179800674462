import React, { useEffect, useRef, useState } from 'react';
import StyledDropzone from './components/Dropzone';

// add typescript to project and use enum
const STATES = {
    START: "start",
    UPLOADING: "uploading"
}

const PH_EVENTS = {
    FILE_SELECTED: "notebook-file-selected",
    CONVERT_STARTED: "notebook-convert-started",
    CONVERT_SUCCESS: "notebook-converted",
    CONVERT_FAILED: "failed-to-convert-notebook",
}


// api args
const apiArgs = [
    { id: "exclude_input", title: "Hide code", defaultValue: 0 }
]

const instructions = [
    "Select a Jupyter Notebook file",
    "Click 'Convert to PDF' button",
    "Save the PDF on your device"
]

function NotebookToPDFConverter({ dispatchPHEvent }) {
    let [state, setState] = useState();
    let [disabled, setDisabled] = useState(true);
    let [file, setFile] = useState();
    let [selectedArgs, setSelectedArgs] = useState();
    let [expandPDFOptions, setExpandPDFOptions] = useState(true);
    let errorMessage = useRef();

    useEffect(() => {
        let defaultSelectedArgs = {}

        apiArgs.map(a => {
            defaultSelectedArgs[a.id] = a.defaultValue
        })

        setSelectedArgs(defaultSelectedArgs)
        setState(STATES.START);
    }, [])


    useEffect(() => {
        setDisabled(!file);
    }, [file])

    useEffect(() => {
        if (state === STATES.START) {
            setDisabled(!file);
        }
        else if (state === STATES.UPLOADING) {
            setDisabled(true);
        }
    }, [state])

    let convertNotebookToPDF = async () => {
        if (disabled) {
            return;
        }

        dispatchNotebookToPDFEvent()


        errorMessage.current.innerText = "";
        setState(STATES.UPLOADING);

        const formData = new FormData();
        formData.append("file", file, file.name);

        // create a method to parse selectedArgs to url with query params
        let url = `${process.env.REACT_APP_API_URL}/notebooks/convert?t='pdf'
            &exclude_input=${selectedArgs['exclude_input']
            }`;


        fetch(url, {
            method: "POST",
            body: formData
        })
            .then((response) => {
                if (response.ok) {
                    return Promise.resolve(response.blob())
                }

                return response.json()
                    .then((json) => {
                        return Promise.reject(json)
                    })
            })
            .then(data => {
                let blob = new Blob([data], { type: "application/pdf" });
                const url = URL.createObjectURL(blob);
                // window.open(url, "_blank")

                let fileName = file.name.split('.').slice(0, -1).join('.');

                const a = document.createElement('a');
                a.setAttribute('download', `${fileName}.pdf`);
                a.setAttribute('href', url);
                a.click();

                dispatchConvertionSuccessEvent()

            })
            .catch(err => {
                let errMessage = `Code: ${err.code}\nDescription : ${err.description || err.message}`;

                errorMessage.current.innerText = errMessage;
                dispatchConvertionFailedEvent(err)
            })
            .finally(() => {
                setState(STATES.START);
            })
    }

    let awsConvertNotebookToPDF = async () => {
        if (disabled) {
            return;
        }

        errorMessage.current.innerText = "";
        setState(STATES.UPLOADING);

        const formData = new FormData();
        formData.append("file", file, file.name);

        // create a method to parse selectedArgs to url with query params
        let url = 'https://s4uvfpynqvxrrk4lurjajugtdm0sycbp.lambda-url.us-east-1.on.aws/';

        fetch(url, {
            method: "POST",
            body: formData
        })
            .then(response => response.blob())
            .then(data => {
                let blob = new Blob([data], { type: "application/pdf" });
                const url = URL.createObjectURL(blob);
                // window.open(url, "_blank")

                let fileName = file.name.split('.').slice(0, -1).join('.');

                const a = document.createElement('a');
                a.setAttribute('download', `${fileName}.pdf`);
                a.setAttribute('href', url);
                a.click();

            })
            .catch(err => {
                errorMessage.current.innerText = err.message;
            })
            .finally(() => {
                setState(STATES.START);
            })
    }

    let queryParamChanged = (argId, e) => {
        let checked = e.target.checked;
        let updatedArgs = JSON.parse(JSON.stringify(selectedArgs));
        updatedArgs[argId] = checked ? 1 : 0;
        setSelectedArgs(updatedArgs);
    }

    // events
    let dispatchFileSelectedEvent = (error) => {
        let event = PH_EVENTS.FILE_SELECTED;
        dispatchPHEvent(event);
    }

    let dispatchNotebookToPDFEvent = () => {
        let properties = {};
        Object.keys(selectedArgs).map(key => {
            properties[key] = selectedArgs[key];
        })

        if (file) {
            properties["file_name"] = file.name
        }

        let event = PH_EVENTS.CONVERT_STARTED;
        dispatchPHEvent(event, properties);
    }


    let dispatchConvertionSuccessEvent = (error) => {
        let properties = {};
        Object.keys(selectedArgs).map(key => {
            properties[key] = selectedArgs[key];
        })

        if (file) {
            properties["file_name"] = file.name
        }

        let event = PH_EVENTS.CONVERT_SUCCESS;
        dispatchPHEvent(event, properties);
    }

    let dispatchConvertionFailedEvent = (error) => {
        let properties = {
            "error_stack": error.stack,
            "error_message": error.message
        };

        if (file) {
            properties["file_name"] = file.name
        }

        let event = PH_EVENTS.CONVERT_FAILED;
        dispatchPHEvent(event, properties);
    }

    return (<div data-testid='converter'>
        <div className='Controllers'>
            <div className='FileSelector'>
                <StyledDropzone
                    onDrop={files => {
                        let file = files[0];

                        setFile(file);
                        dispatchFileSelectedEvent();
                    }}
                    onDropRejected={() => {
                        alert("File type is not allowed. You can only upload .ipynb files")
                    }}
                />

                <div className='SelectedFile'>
                    Selected file : {file ? file.name : 'No file'}
                </div>

            </div>

            <div className={`ArgsController ${expandPDFOptions ? 'expanded' : ''}`}>
                <p>Advanced PDF options
                    <CaretIcon onClick={() => {
                        setExpandPDFOptions(!expandPDFOptions)
                    }} />
                </p>

                {
                    expandPDFOptions ?
                        apiArgs.map(a => {
                            return <div key={a.id}>
                                <input type="checkbox" onChange={(e) => { queryParamChanged(a.id, e) }} /> <span>{a.title}</span>
                            </div>
                        })
                        :
                        ""
                }

            </div>

            <div className='Actions'>
                <div
                    className={`Button ${disabled ? "Disabled" : ""}`}
                    onClick={convertNotebookToPDF}>
                    <span>
                        {
                            state === STATES.UPLOADING ? "Converting..." : "Convert to PDF"
                        }

                    </span>
                </div>
                <div className='ErrorMessage' ref={errorMessage} ></div>
            </div>





        </div>

        <div className='Instructions'>
            <h4>How to use Notebook Converter?</h4>
            <ol>
                {
                    instructions.map((section, i) => {
                        return <li key={i}>{section}</li>
                    })
                }
            </ol>

            <ul>
                <li>We do not store Notebooks or PDFs</li>
                <li>Processing time can be affected by input file size and internet connection speed</li>
            </ul>

        </div>

    </div>)
}

export default NotebookToPDFConverter;


function CaretIcon({ onClick }) {
    return (
        <img onClick={onClick} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABmJLR0QA/wD/AP+gvaeTAAAAo0lEQVRIie3STaqBYRTA8Z+ukis7sgwTQzuxAckejFEGUiZiG5JV3G69YeBVb74/jtnzrzN7Or9OPaRSqcgGyLAPmizfedE2EDnN5hrUxC4Q2eU7r9YLhLq3EPjBMACZoHwPgl+sPkCWqD5CTtUwfwNZoP4sUrxs9gIyfeWS8yoYP4GM8rcfVULnDtJ3/ERhtfBXAP7RjgSKNbDOp/EtJJWK6QBceqOraqxeeAAAAABJRU5ErkJggg=="></img>
    )
}