import { useEffect } from "react";
import NotebookToPDFConverter from "./components/NotebookToPDFConverter";
import posthog from 'posthog-js';
import { Footer } from "./components/templates";

import './index.css';

function App() {

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'dev') {
      posthog.init(process.env.REACT_APP_PH_API_KEY, { api_host: 'https://app.posthog.com', autocapture: true });
    }
  }, [])

  let dispatchPHEvent = (event, properties = {}) => {
    if (process.env.REACT_APP_ENV === 'dev') {
      return
    }

    let distinctId = posthog.persistence.props.distinct_id;
    let url = `https://app.posthog.com/capture`;

    let request = {
      "api_key": process.env.REACT_APP_PH_API_KEY,
      "event": event,
      "properties": {
        "distinct_id": distinctId,
        ...properties
      },

    }

    // REACT_APP_HP_KEY
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(request)
    })
      .then(response => response.json())
      .catch(err => {
        console.log(err)
      })
  }


  return (
    <div className="App">
      <h1 className="Title">Convert Jupyter Notebooks to PDF online</h1>

      <NotebookToPDFConverter dispatchPHEvent={dispatchPHEvent} />

      <div className="CallToAction">
        <h1>Deploy your notebooks and applications on Ploomber Cloud</h1>

        <div className="Button" onClick={() => {
          dispatchPHEvent("try-ploomber-cloud-button")
          window.open("https://www.platform.ploomber.io/", "_blank")
        }}>
          Try it now
        </div>
      </div>

      <Footer />

    </div>
  );
}

export default App;
